import React from 'react'
import { StaticQuery, graphql} from 'gatsby'
import TwoColScreen from '../../components/TwoColScreen'


export default () => (
  <StaticQuery
    query={graphql`
      query About {
        contentfulAboutUs {
          title
          verticalText
          largeText {
            childMarkdownRemark {
              html
            }
          }
          smallText {
            childMarkdownRemark {
              html
            }
          }        
        }
      }
    `}
    render={data => (

      <TwoColScreen
        title={data.contentfulAboutUs.title}
        verticalText={data.contentfulAboutUs.verticalText}
        largeText={data.contentfulAboutUs.largeText}
        smallText={data.contentfulAboutUs.smallText}
      />

    )}
  />
)