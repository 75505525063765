import React from 'react'
import { StaticQuery, graphql} from 'gatsby'
import TwoColScreen from '../../components/TwoColScreen'


export default () => (
  <StaticQuery
    query={graphql`
      query WhatWeDo
       {
        contentfulWhatWeDo {
          title
          verticalText
          largeText {
            childMarkdownRemark {
              html
            }
          }
          smallText {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    `}
    render={data => (

      <TwoColScreen
        title={data.contentfulWhatWeDo.title}
        verticalText={data.contentfulWhatWeDo.verticalText}
        largeText={data.contentfulWhatWeDo.largeText}
        smallText={data.contentfulWhatWeDo.smallText}
      />

    )}
  />
)