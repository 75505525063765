import React from 'react'
import { StaticQuery, graphql} from 'gatsby'
import TwoColScreen from '../../components/TwoColScreen'

export default () => (
  <StaticQuery
    query={graphql`
      query Background {
        contentfulBackground {
          title
          verticalText
          largeText {
            childMarkdownRemark {
              html
            }
          }
          smallText {
            childMarkdownRemark {
              html
            }
          }         
        }
      }
    `}
    render={data => (

      <TwoColScreen
        title={data.contentfulBackground.title}
        verticalText={data.contentfulBackground.verticalText}
        largeText={data.contentfulBackground.largeText}
        smallText={data.contentfulBackground.smallText}
      />
    )}
  />
)