import React from 'react'
import { StaticQuery, graphql} from 'gatsby'
import config from '../../utils/siteConfig'
import FullPageSection from '../../components/FullPageSection'
import SectionContainer from '../../components/SectionContainer'
import ContentWrapper from '../../components/ContentWrapper'
import HeadingMedium from '../../components/HeadingMedium'
import HeadingLarge from '../../components/HeadingLarge'
import { Grid, Row, Col } from 'react-flexbox-grid'



export default () => (
  <StaticQuery
    query={graphql`
      query Homescreen {
        contentfulHomeScreen {
          title
          verticalText
          homeScreenText {
            homeScreenText
          }
        }
      }
    `}
    render={data => (
      <FullPageSection>
        <SectionContainer noBG>
          

          <ContentWrapper showScroll verticalText={data.contentfulHomeScreen.verticalText}>

            <Grid fluid>
              <Row>
                <Col lg={12}>
                  <HeadingMedium center>{config.siteTitle}</HeadingMedium>
                  <HeadingLarge center>{data.contentfulHomeScreen.homeScreenText.homeScreenText}</HeadingLarge>
                </Col>
              </Row>
            </Grid>          
          
          </ContentWrapper>
        </SectionContainer> 
      </FullPageSection>
    )}
  />
)