import React from 'react'
import { Grid, Col } from 'react-flexbox-grid';
import CenterRow from '../components/CenterRow'
import PageBody from '../components/PageBody'
import LargeText from '../components/LargeText'
import HeadingMedium from '../components/HeadingMedium'
import HeadingLarge from '../components/HeadingLarge'


const SlidePerson = props => {
    return (
        <div className="slide">
        <Grid fluid>
        <CenterRow>
            <Col>
                <HeadingMedium>{props.title}</HeadingMedium>
                <HeadingLarge showBorder>{props.name}</HeadingLarge>

                <LargeText>
                    <PageBody body={props.bullets} className="dashed" />
                </LargeText>               
            </Col>                
        </CenterRow>
        </Grid>
    </div>
    )
}

export default SlidePerson