import React from 'react'
import { StaticQuery, graphql} from 'gatsby'
import FullPageSection from '../../components/FullPageSection'
import SectionContainer from '../../components/SectionContainer'
import ContentWrapper from '../../components/ContentWrapper'
import SlidePerson from '../../components/SlidePerson'


export default () => (
  <StaticQuery
    query={graphql`
      query WhoWeAre {
        contentfulWhoWeAre {
          title
          verticalText
          people {
            id
            name
            bulletPoints {
              childMarkdownRemark {
                html
              }
            }
          }        
          
        }
      }
    `}
    render={data => (

      
      <FullPageSection>    


        <SectionContainer>


          <ContentWrapper fullWidthContent verticalText={data.contentfulWhoWeAre.verticalText}>

            {data.contentfulWhoWeAre.people.map(person => {
              return <SlidePerson key={person.id} title={data.contentfulWhoWeAre.title} name={person.name} bullets={person.bulletPoints} />              
            })}
  

          </ContentWrapper>

       

        </SectionContainer> 
      </FullPageSection>
    )}
  />
)