const sizes = {
    xs: 0,
    mobile: '576px',
    tablet: '768px',
    desktop: '992px',
    xl: '1200px'    
}

const breakpoints = {
    mobile: `(min-width: ${sizes.mobile})`,
    tablet: `(min-width: ${sizes.tablet})`,
    desktop: `(min-width: ${sizes.desktop})`,
    xl: `(min-width: ${sizes.xl})`,
}

export default breakpoints;
