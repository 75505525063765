const theme = {
  colors: {
    base: '#ffffff', // Black
    primary: '#b0a084',
    secondary: '#e9e9e9', // Medium Gray
    tertiary: '#f3f3f3', // Light Gray
    highlight: '#5b8bf7', // Light Blue
    bullet: '#6d6d6d'
  },
  rgbs: {
    primary: '176,160,132'
  },
  fonts: {
    sans: 'neue-haas-unica, sans-serif',
    serif: 'GT Super Display, sans-serif',
  },
  fontSizes: {
    small: {
      min: '10px',
      max: '16px'
    },
    medium: {
      min: '18px',
      max: '22px'
    },
    large: {
      min: '30px',
      max: '50px'
    },
    jumbo: {
      min: '42px',
      max: '80px'
    },
  },
  sizes: {
    maxWidth: '1050px',
    maxWidthCentered: '650px',
  },
  responsive: {
    small: '35em',
    medium: '50em',
    large: '70em',
  },
  transitions: {
    default: '.5s ease',
    fast: '.2s ease',
    slow: '1s ease-in-out'
  }
}

export default theme
